<template>
  <el-card class="box-card" shadow="hover">
    <h2>应用：{{ applicationName }} 配置修改</h2>
    <el-divider></el-divider>
    <el-form :model="viewItems" ref="compForm">
      <el-form-item
        v-for="(desc, field) in nonReactiveData['showFields']"
        :key="field"
        :label="field"
        :prop="field"
        :rules="desc['rules']"
      >
        <el-checkbox
          v-if="desc['field_type'] === 'boolean'"
          v-model="viewItems[field]"
          >{{ viewItems[field] }}</el-checkbox
        >

        <el-input
          v-if="desc['field_type'] === 'string'"
          v-model="viewItems[field]"
        ></el-input>
        <el-input
          v-if="desc['field_type'] === 'int'"
          v-model.number="viewItems[field]"
        ></el-input>
      </el-form-item>

      <el-switch v-model="helpers.advancedOptsShow" inactive-text="展开参数" />
      <br />

      <!-- 隐藏的节点 -->
      <el-form
        v-if="helpers.advancedOptsShow"
        :model="viewItems"
        ref="compHiddenForm"
        ><el-scrollbar height="500px" always>
          <el-form-item
            v-for="(desc, field) in nonReactiveData['hiddenFields']"
            :key="field"
            :label="field"
            :prop="field"
            :rules="desc['rules']"
          >
            <el-checkbox
              v-if="desc['field_type'] === 'boolean'"
              v-model="viewItems[field]"
              >{{ viewItems[field] }}</el-checkbox
            >
            <el-input
              v-if="desc['field_type'] === 'string'"
              v-model="viewItems[field]"
            ></el-input>
            <el-input
              v-if="desc['field_type'] === 'int'"
              v-model.number="viewItems[field]"
            ></el-input>
          </el-form-item>
        </el-scrollbar>
      </el-form>
    </el-form>
    <!-- <el-form-item > -->
    <el-button class="submit-button" type="primary" @click="$router.go(-1)"
      >返回</el-button
    >
    <el-button
      class="submit-button"
      type="primary"
      @click="validateAndSubmit(viewItems)"
      >提交</el-button
    >
    <!-- </el-form-item> -->
  </el-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      clusterPrimaryKey: this.$route.params.clusterPrimaryKey,
      applicationName: this.$route.params.appActivityName,
      viewItems: {}, // 表单双向绑定的视图数据
      helpers: {
        advancedOptsShow: false, // 高级选项字段的展示与否
      },
      nonReactiveData: {},
      // 之后可以改进, 因为只要在data中的, vue3都会使用代理对象对其进行封装, 但由于对性能的影响不大, 目前可以放在data中

    };
  },
  methods: {
    getCompItems() {
      axios({
        method: "post",
        url: "/api/driver/getApplicationStatus",
        data: {
          clusterPrimaryKey: this.$route.params.clusterPrimaryKey,
          applicationName: this.$route.params.appActivityName,
        }, //请求体待协商
      })
        .then((res) => {
          console.log(res);
          const status = res.data.code;
          if (status === 200) {
            console.log(res.data);
            console.log("配置修改完成，跳转至进度页面...");
            this.$router.push({
              name: "BuildingStatus",
              params: { clusterId: this.$store.getters.clusterId },
            });
          } else {
            this.$alert(res.data.message, "提示:", {
              confirmButtonText: "确定",
              callback: (action) => {
                // 用户点击确认之后的逻辑可以卸载这里
              },
            });
          }
        })
        .catch((e) => {
          this.$alert("系统错误", "提示:", {
            confirmButtonText: "确定",
            callback: (action) => {
              // 用户点击确认之后的逻辑可以卸载这里
            },
          });
        });
    },

    getFormObj() {
      // 以后可以在这进行从后端获取数据以及formObj的映射
      const configs = this.nonReactiveData["configs"];
      return this.attachRules(this.removeNoDisplay(configs));
    },

    removeNoDisplay(configs) {
      const obj = {};
      for (let [field, desc] of Object.entries(configs)) {
        if (desc["field_type"] === "default") {
          continue;
        }
        obj[field] = desc;
      }
      return obj;
    },

    attachRules(configs) {
      const obj = {};

      for (let [field, desc] of Object.entries(configs)) {
        const compoundDesc = JSON.parse(JSON.stringify(desc));
        compoundDesc["rules"] = [];
        if (desc["is_required"]) {
          compoundDesc["rules"].push({
            required: true,
            message: field + "是必填的",
          });
        }
        if (desc["field_type"] === "int") {
          compoundDesc["rules"].push({
            type: "number",
            message: field + "必须为数字值",
          });
        }
        obj[field] = compoundDesc;
      }
      return obj;
    },

    attachDefaultFields() {
      const attachedFields = JSON.parse(JSON.stringify(this.viewItems));
      const configs = this.nonReactiveData["configs"];
      for (let [field, desc] of Object.entries(configs)) {
        if (desc["field_type"] === "default") {
          attachedFields[field] = desc["field_default"];
        }
      }
      return attachedFields;
    },

    validateAndSubmit(viewItems) {
      const p1 = this.$refs["compForm"].validate();
      const p2 = this.$refs["compHiddenForm"]
        ? this.$refs["compHiddenForm"].validate()
        : Promise.resolve(true);
      Object.keys(this.nonReactiveData.configs).forEach(k=>{
        if(viewItems[k]===null||viewItems[k]===undefined){
          console.log(k+"============>"+this.nonReactiveData.configs[k]);
           viewItems[k] = this.nonReactiveData.configs[k].field_default
        }
      })
      Promise.all([p1, p2])
        .then((arr) => {
          const validateRes = arr.every((item) => item);
          if (validateRes) {
            let reqData = {
              clusterPrimaryKey:this.clusterPrimaryKey ,
              appName:this.applicationName,
              configs:viewItems
            }
            console.log(JSON.stringify(reqData));
            axios({
              method: "post",
              url: "/api/driver/editapp",
              data: reqData, //请求体待协商
            })
              .then((res) => {
                console.log(res);
                const status = res.data.code;
                if (status === 200) {
                  console.log(res.data);
                  console.log("配置修改完成，跳转至进度页面...");
                  this.$router.push({
                    name: "BuildingStatus",
                    params: { clusterId: this.$store.getters.clusterId },
                  });
                } else {
                  this.$alert(res.data.message, "提示:", {
                    confirmButtonText: "确定",
                    callback: (action) => {
                      // 用户点击确认之后的逻辑可以卸载这里
                    },
                  });
                }
              })
              .catch((e) => {
                this.$alert("系统错误", "提示:", {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    // 用户点击确认之后的逻辑可以卸载这里
                  },
                });
              });
          }
        })
        .catch((err) => {
          let errMessage = "";
          Object.keys(err).forEach((key) => {
            errMessage += err[key][0].message + " ";
          });
          this.$message({
            message: "请检查填写的表单:\r\n" + errMessage + "\r\n",
            type: "warning",
          });
        });
    },
    getShowAndHiddenFields() {
      const formObj = this.nonReactiveData["formObj"];
      const showFields = {};
      const hiddenFields = {};

      for (let [field, desc] of Object.entries(formObj)) {
        if (desc["is_flat"]) {
          showFields[field] = desc;
        } else {
          hiddenFields[field] = desc;
        }
      }

      return {
        showFields: showFields,
        hiddenFields: hiddenFields,
      };
    },

    getViewItems() {
      const formObj = this.nonReactiveData["formObj"];
      for (let [field, desc] of Object.entries(formObj)) {
        this.viewItems[field] = desc["field_default"];
      }
    },

    // freshFormObj(config,configFormObj){
    //   config = JSON.parse(decodeURI(config));
    //   configFormObj = JSON.parse(decodeURI(configFormObj));
    //   configFormObj.keys().map(key=>{
    //     configFormObj.field_default[key] = config[key]
    //   })
    //   return configFormObj
    // }
  },

  created() {
    // 在created中做好初始化工作
    axios({
      method: "get",
      url: "/api/driver/getApplicationStatus",
      params: {
        clusterPrimaryKey: this.$route.params.clusterPrimaryKey,
        applicationName: this.$route.params.appActivityName,
      }, //请求体待协商
    }).then((res) => {
      this.nonReactiveData.name = res.data.data.applicationName;
      this.nonReactiveData.configs = JSON.parse(
        decodeURI(res.data.data.applicationValues)
      );
      // this.nonReactiveData.configs = this.freshFormObj(res.data.data.applicationValues,res.data.data.applicationValuesXXX)

      this.nonReactiveData["formObj"] = this.getFormObj();
      const { showFields, hiddenFields } = this.getShowAndHiddenFields();
      this.nonReactiveData["showFields"] = showFields;
      this.nonReactiveData["hiddenFields"] = hiddenFields;
      this.getViewItems();
    });
  },

  watch: {
    "helpers.advancedOptsShow"(val) {
      if (val === false) {
        const p = this.$refs["compHiddenForm"].validate();
        p.then(() => {}).catch(() => {
          this.helpers.advancedOptsShow = true;
        });
      }
    },
  },
};
</script>

<style lang="less">
.switch {
  margin-bottom: 0.1rem;
}
.submit-button {
  margin-top: 2%;
}
</style>